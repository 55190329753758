export const EMAIL_ERROR = 'Please enter your mail id';
export const FULLNAME_ERROR = 'Please enter your  fullname';
export const PASSWORD_ERROR = 'Please enter your password';
export const INVALID_EMAIL_ERROR = 'Please enter a valid mail id';
export const INVALID_PASSWORD_ERROR =
  'Enter a new password satisfying constraints: Atleast eight characters with one capital letter and small letter, one special character, one number';
export const PASSWORD_MISMATCH_ERROR = 'The passwords do not match';

export const NUMBER_REGEX = /^[0-9\b]+$/;
export const PASSWORD_REGEX =
  /^\s*(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~ ])[A-Za-z\d !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,}\s*$/;
export const EMAIL_REGEX = /^\s*[A-Z0-9._%+-]+@[A-Z0-9.-]+(\.[A-Z]+)+\s*$/i;
export const BUSINESS_EMAIL_REGEX =
  /(^[a-zA-Z0-9.%+-]+@(?!gmail.com)(?!qq.com)(?!yahoo.com)(?!hotmail.com)(?!yahoo.co.in)(?!aol.com)(?!live.com)(?!outlook.com)[a-zA-Z0-9-]+.[a-zA-Z0-9-.]{1,61}$)/;
export const YOUTUBE_REGEX =
  /^(http(s)??:\/\/)?(www\.)?((youtube\.com\/watch\?v=)|(youtu.be\/))([a-zA-Z0-9\-_])+/;
export const FILE_UPLOAD_VALIDATION =
  'Cannot upload more than one video & audio file. Please try again.';
export const VIDEO_FILE_MIME_TYPES =
  'video/x-flv,video/mp4,video/3gpp,video/x-msvideo,video/webm,video/ms-asf,video/quicktime,video/x-matroska,video/mpeg';
export const VIDEO_FILE_FORMATS = [
  '.webm',
  '.mkv',
  '.flv',
  '.avi',
  '.mov',
  '.wmv',
  '.mp4',
  '.mpg',
  '.mpeg',
  '.svi',
  '.3gp'
];
export const AUDIO_FILE_MIME_TYPES = 'audio/mpeg';
export const AUDIO_FILE_FORMATS = ['.mp3', '.wav'];

export const AUDIO_VIDEO_FILE_FORMATS = [...VIDEO_FILE_FORMATS, ...AUDIO_FILE_FORMATS];
export const NO_TRACK_MSG = 'No Background Track';
export const SOURCE_TRACK_MSG = 'Source Background Track';
export const SOURCE_VERBAL_TRACK_MSG = 'Source Background Track + Non-verbal Sounds';
export const RESET_PASSWORD_MAIL_ERROR = 'The mail has not been sent.Please try again!';
export const CHANGE_PASSWORD_ERROR = 'The password has not been changed. Please try again.';
export const CSV_ERRORS = {
  importError: `Make sure the file format is correct and variables in your file matches the tags and variable on the chart and in the same order`,
  uploadError: 'CSV upload failed, please try again'
};
