import { AxiosError, AxiosResponse } from 'axios';
import { useRouter } from 'next/router';
import { useCallback, useState } from 'react';
import LocalStorage from 'services/storage';
import { SignupPayload } from 'types/AuthTokens';
import { ErrorResponse } from 'types/Error';
import { CreateUserResponseType, User } from 'types/User';
import { sendAmplitudeData } from 'utils/amplitude';

import { USER_LOGIN } from 'constants/eventNames';

import {
  createAccount,
  deleteUser,
  forgotUserPassword,
  login,
  logout,
  resetUserPassword,
  sendVerificationMail,
  updateUserDetails,
  verifyEmail
} from '../services/authService';
import { formatErrorDetails } from '../utils/error';

const useAuth = (): {
  loginUser: (username: string, password: string) => void;
  isLoginSuccess: boolean;
  loginError: string;
  signUp: (signupData: SignupPayload) => Promise<{
    error: ErrorResponse | null;
    response: AxiosResponse<CreateUserResponseType> | null;
  }>;
  signUpError: { error: string; message: string };
  logoutUser: () => void;
  isLogoutSucess: boolean;
  isLoading: boolean;
  isSignupLoading: boolean;
  isLinkSend: boolean;
  updateAccountDetails: (
    name: string,
    first_name: string,
    last_name: string,
    phone_number: string
  ) => Promise<User>;
  forgotPassword: (email: string) => Promise<string | null>;
  forgotPasswordError: string;
  resetPassword: (password: string, email: string) => void;
  sendUserVerificationMail: (email: string) => Promise<string | null>;
  verifyUserMail: () => Promise<void>;
  isPasswordReset: boolean;
  isUserVerified: boolean;
  deleteAccount: () => void;
} => {
  const history = useRouter();
  const [isLoginSuccess, setIsLoginSuccess] = useState<boolean>(false);
  const [loginError, setLoginError] = useState<string>('');
  const [signUpError, setSignUpError] = useState<{
    error: string;
    message: string;
  }>();
  const [isLogoutSucess, setIsLogoutSuccess] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSignupLoading, setIsSignupLoading] = useState<boolean>(false);
  const [isLinkSend, setIsLinkSend] = useState<boolean>(false);
  const [forgotPasswordError, setForgotPasswordError] = useState<string>('');
  const [isPasswordReset, setIsPasswordReset] = useState<boolean>(false);
  const [isUserVerified, setIsUserVerified] = useState<boolean>(false);

  const loginUser = async (username: string, password: string): Promise<void> => {
    try {
      setIsLoading(true);
      await login(username, password);
      setIsLoginSuccess(true);
      LocalStorage.setItem('username', username);
      sendAmplitudeData(USER_LOGIN, { Username: username });
      history.push('/dashboard');
    } catch (error) {
      const errorMessage = error as AxiosError;
      const errorDetails = formatErrorDetails(errorMessage);
      setLoginError(errorDetails?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const signUp = async (payload: SignupPayload) => {
    try {
      setIsSignupLoading(true);
      const response = await createAccount({ ...payload });
      setIsLoginSuccess(true);
      return { response, error: null };
    } catch (error) {
      const errorMessage = error as AxiosError;
      const errorDetails = formatErrorDetails(errorMessage);
      setSignUpError(errorDetails);
      return { error: errorDetails, response: null };
    } finally {
      setIsSignupLoading(false);
    }
  };

  const logoutUser = async () => {
    try {
      await logout();
      history.push('/login');
      setIsLogoutSuccess(true);
    } catch (error) {
      setIsLogoutSuccess(false);
    }
  };

  const forgotPassword = async (email: string): Promise<string | null> => {
    try {
      setIsLoading(true);
      const success = await forgotUserPassword(email);
      setIsLinkSend(success);
      return success as unknown as string;
    } catch (error) {
      const errorMessage = error as AxiosError;
      const errorDetails = formatErrorDetails(errorMessage);
      setForgotPasswordError(errorDetails?.error);
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  const resetPassword = async (password: string, email: string): Promise<void> => {
    try {
      setIsLoading(true);
      const success = await resetUserPassword(password, email);
      setIsPasswordReset(success);
      history.push('/login');
    } catch (error) {
      const errorMessage = error as AxiosError;
      const errorDetails = formatErrorDetails(errorMessage);
      setForgotPasswordError(errorDetails?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const sendUserVerificationMail = async (email: string): Promise<string | null> => {
    try {
      setIsLoading(true);
      const success = await sendVerificationMail(email);
      setIsLinkSend(success);
      return success as unknown as string;
    } catch (error) {
      const errorMessage = error as AxiosError;
      const errorDetails = formatErrorDetails(errorMessage);
      setForgotPasswordError(errorDetails?.error);
      return null;
    } finally {
      setIsLoading(false);
    }
  };
  const deleteAccount = useCallback(async () => {
    try {
      setIsLoading(true);
      await deleteUser();
      history.push('/login');
    } catch (error) {
      const errorMessage = error as AxiosError;
      const errorDetails = formatErrorDetails(errorMessage);
      setForgotPasswordError(errorDetails?.message);
    } finally {
      setIsLoading(false);
    }
  }, [history]);
  const updateAccountDetails = async (
    name: string,
    first_name: string,
    last_name: string,
    phone_number: string
  ) => {
    try {
      setIsLoading(true);
      const response = await updateUserDetails(name, first_name, last_name, phone_number);
      return response;
    } catch (error) {
      const errorMessage = error as AxiosError;
      const errorDetails = formatErrorDetails(errorMessage);
      setForgotPasswordError(errorDetails?.message);
      return null;
    } finally {
      setIsLoading(false);
    }
  };
  const verifyUserMail = async (): Promise<void> => {
    try {
      setIsLoading(true);
      const success = await verifyEmail();
      setIsUserVerified(success);
      history.push('/login');
    } catch (error) {
      const errorMessage = error as AxiosError;
      const errorDetails = formatErrorDetails(errorMessage);
      setForgotPasswordError(errorDetails?.message);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    loginUser,
    isLoginSuccess,
    loginError,
    signUp,
    signUpError,
    logoutUser,
    isLogoutSucess,
    isLoading,
    isSignupLoading,
    forgotPassword,
    isLinkSend,
    forgotPasswordError,
    resetPassword,
    isPasswordReset,
    sendUserVerificationMail,
    verifyUserMail,
    isUserVerified,
    deleteAccount,
    updateAccountDetails
  };
};

export default useAuth;
