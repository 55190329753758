import { login_strings as strings } from 'i18n';
const passwordConditions = () => (
  <div>
    {strings.passwordCondition.primary_condition}
    <br />
    {strings.passwordCondition.min_char}
    <br />
    {strings.passwordCondition.alphabet_condition}
    <br />
    {strings.passwordCondition.alphabet_requirement}
    <br />
    {strings.passwordCondition.digit_requirement}
    <br />
    {strings.passwordCondition.specialchar_requirement}
  </div>
);

export { passwordConditions };
