import { Button } from '@libs/atoms/Button';
import { Email, InfoRounded, Visibility, VisibilityOff } from '@mui/icons-material';
import {
  CircularProgress,
  IconButton,
  InputAdornment,
  OutlinedInput,
  TextField,
  Tooltip
} from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import useAuth from 'hooks/useAuth';
import { login_strings as strings } from 'i18n';
import _isEmpty from 'lodash/isEmpty';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { PASSWORD_REGEX } from 'constants/validations';

import { passwordConditions } from '../utils';

import styles from './SignUp.module.scss';

const animationProps = {
  initial: { y: -50, opacity: 0.2 },
  animate: { y: 0, opacity: 1 },
  exit: { y: -10, opacity: 0 }
};

interface IFormInput {
  email: string;
  name: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  password: string;
  confirmPassword: string;
  confirmPasswordType: 'text' | 'password';
  passwordType: 'text' | 'password';
  access_code: string;
  validPassword: boolean;
}

const SignUp: React.FC<{
  setIsNewUser: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ setIsNewUser }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue
  } = useForm<IFormInput>({
    mode: 'onSubmit',
    defaultValues: {
      confirmPasswordType: 'password',
      passwordType: 'password',
      validPassword: true
    }
  });

  // const setSuccessMsg = useSetRecoilState(SuccessState);

  const {
    isSignupLoading,
    signUp,
    signUpError,
    isLoginSuccess,
    isLoading,
    sendUserVerificationMail
  } = useAuth();
  const validPassword = watch('validPassword');

  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    const { email, password, name, first_name, last_name, phone_number, access_code } = data;
    setValue('validPassword', PASSWORD_REGEX.test(password));
    if (PASSWORD_REGEX.test(password) && !isLoading) {
      const { response } = await signUp({
        password,
        email,
        name,
        first_name,
        last_name,
        phone_number,
        access_code
      });
      if (response) {
        // setSuccessMsg('Please verify your email to continue.');
      }
    }
  };

  const onResendEmail: SubmitHandler<IFormInput> = async ({ email }) => {
    sendUserVerificationMail(email);
  };

  const handleClickShowPassword = (type: 'confirmPasswordType' | 'passwordType') => {
    if (watch(type) === 'password') setValue(type, 'text');
    else setValue(type, 'password');
  };

  const { onChange: onChangePassword, ...otherPasswordMethods } = {
    ...register('password', { required: true })
  };
  const onChangePasswordWrapper = (e) => {
    setValue('validPassword', true);
    onChangePassword(e);
  };

  return (
    <>
      <div className={`flex flex-col justify-center  ${styles.container}`}>
        {!isLoginSuccess ? (
          <>
            <span className="pb-4 text-3xl  font-bold">Sign Up</span>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="mt-6 flex h-[105px] flex-col">
                <span className="pb-1 text-lg font-semibold after:content-['*'] ">Email</span>
                <TextField
                  {...register('email', { required: true })}
                  type="text"
                  placeholder="Enter Your email"
                  margin="normal"
                  name="email"
                  autoComplete="off"
                  InputProps={{
                    classes: {
                      root: `${styles.textRoot}`,
                      input: `${styles.textInput}`
                    },
                    disableUnderline: true
                  }}
                  FormHelperTextProps={{
                    className: styles.helperText
                  }}
                />
                <AnimatePresence>
                  {!_isEmpty(errors.email) && (
                    <motion.span {...animationProps} className="text-md py-1   text-red-600">
                      {strings.mail.enterEmail}
                    </motion.span>
                  )}
                </AnimatePresence>
              </div>
              <div className="flex flex-row">
                <div className="mt-6 flex h-[105px] flex-col">
                  <span className="text-md p-gray pb-1">First Name</span>
                  <TextField
                    {...register('first_name', { required: false })}
                    type="text"
                    placeholder="Enter First Name"
                    margin="normal"
                    name="first_name"
                    autoComplete="off"
                    InputProps={{
                      classes: {
                        root: `${styles.textRoot}`,
                        input: `${styles.textInput}`
                      },
                      disableUnderline: true
                    }}
                    FormHelperTextProps={{
                      className: styles.helperText
                    }}
                  />
                </div>
                <div className="ml-4 mt-6 flex h-[105px] flex-col">
                  <span className="text-md p-gray pb-1">Last Name</span>
                  <TextField
                    {...register('last_name', { required: false })}
                    type="text"
                    placeholder="Enter Last Name"
                    margin="normal"
                    name="last_name"
                    autoComplete="off"
                    InputProps={{
                      classes: {
                        root: `${styles.textRoot}`,
                        input: `${styles.textInput}`
                      },
                      disableUnderline: true
                    }}
                    FormHelperTextProps={{
                      className: styles.helperText
                    }}
                  />
                </div>
              </div>
              <div className="mt-4 flex h-[105px] flex-col">
                <span className="text-md p-gray pb-1 after:content-['*']">Name</span>
                <TextField
                  {...register('name', { required: true })}
                  type="text"
                  placeholder="Enter Name"
                  margin="normal"
                  name="name"
                  autoComplete="off"
                  InputProps={{
                    classes: {
                      root: `${styles.textRoot}`,
                      input: `${styles.textInput}`
                    },
                    disableUnderline: true
                  }}
                  FormHelperTextProps={{
                    className: styles.helperText
                  }}
                />
                <AnimatePresence>
                  {errors.name && (
                    <motion.span {...animationProps} className="mb-10 py-1 text-sm text-red-600">
                      {strings.signUp.name}
                    </motion.span>
                  )}
                </AnimatePresence>
              </div>
              <div className="mt-4 flex h-[105px] flex-col">
                <span className="text-md p-gray pb-1 after:content-['*']">Phone Number</span>
                <TextField
                  {...register('phone_number', { required: true })}
                  type="text"
                  placeholder="Enter Phone Number"
                  margin="normal"
                  name="phone_number"
                  autoComplete="off"
                  InputProps={{
                    classes: {
                      root: `${styles.textRoot}`,
                      input: `${styles.textInput}`
                    },
                    disableUnderline: true
                  }}
                  FormHelperTextProps={{
                    className: styles.helperText
                  }}
                />
                <AnimatePresence>
                  {errors.name && (
                    <motion.span {...animationProps} className="py-1 text-sm  text-red-600">
                      {strings.signUp.phonenumber}
                    </motion.span>
                  )}
                </AnimatePresence>
              </div>
              <div className="mt-4 flex h-[105px] flex-col">
                <span className="text-md p-gray pb-1 after:content-['*']">Access Code</span>
                <TextField
                  {...register('access_code', { required: true })}
                  type="text"
                  placeholder="Enter Access Code"
                  margin="normal"
                  name="access_code"
                  autoComplete="off"
                  InputProps={{
                    classes: {
                      root: `${styles.textRoot}`,
                      input: `${styles.textInput}`
                    },
                    disableUnderline: true
                  }}
                  FormHelperTextProps={{
                    className: styles.helperText
                  }}
                />
                <AnimatePresence>
                  {errors.name && (
                    <motion.span {...animationProps} className="py-1 text-sm  text-red-600">
                      {strings.signUp.accessCode}
                    </motion.span>
                  )}
                </AnimatePresence>
              </div>
              <div className="mt-6 flex h-[105px] max-w-[450px] flex-col">
                <span className="text-md p-gray pb-1 after:content-['*']">
                  {strings.password.password}
                </span>
                <OutlinedInput
                  onChange={onChangePasswordWrapper}
                  {...otherPasswordMethods}
                  type={watch('passwordType')}
                  placeholder="Password"
                  autoComplete="off"
                  className="max-w-[400px]"
                  error={!validPassword}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => handleClickShowPassword('passwordType')}
                        edge="end"
                      >
                        {watch('passwordType') === 'text' ? <Visibility /> : <VisibilityOff />}
                      </IconButton>

                      <Tooltip open={!validPassword} title={passwordConditions()}>
                        <IconButton aria-label="toggle password visibility" edge="end">
                          <InfoRounded />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  }
                />
                <AnimatePresence>
                  {!_isEmpty(errors.password) && (
                    <motion.span
                      {...animationProps}
                      className={`py-1 text-sm text-red-600 ${styles.errorMsg}`}
                    >
                      {strings.signUp.enterPassword}
                    </motion.span>
                  )}
                </AnimatePresence>
              </div>
              {signUpError && (
                <div className="flex h-[40px] max-w-[360px] content-end overflow-scroll text-sm text-red-600">
                  {signUpError?.message}
                </div>
              )}

              <Button onClick={handleSubmit(onSubmit)} className={styles.button} variant="primary">
                {isSignupLoading ? (
                  <CircularProgress color="secondary" size={25} />
                ) : (
                  <>{strings.signUp.signUp}</>
                )}
              </Button>

              <div className="mt-5  flex max-w-[400px] items-center justify-between">
                <motion.span
                  className="font-inter"
                  whileHover={{ scale: 1.05 }}
                  whileFocus={{ scale: 1.05 }}
                >
                  Already have an account?
                </motion.span>
                <Button
                  variant="generic"
                  className="!w-fit !rounded-md"
                  onClick={() => setIsNewUser((type) => !type)}
                >
                  <span className="MuiButton-label">{strings.login.loginNow}</span>
                </Button>
              </div>
            </form>
          </>
        ) : (
          <div className="flex flex-col items-center justify-center text-black">
            <div className={styles.confirmEmailContainer}>
              <div>
                <Email style={{ maxWidth: '460px' }} />
              </div>
              Confirm your Email!
            </div>
            <div className={styles.confirmText}>
              Your account has been successfully registered. To complete the process please check
              your email for validation request.
            </div>
            <Button
              onClick={handleSubmit(onResendEmail)}
              className={styles.button}
              color="primary"
              variant="secondary"
            >
              {isLoading ? <CircularProgress color="secondary" size={25} /> : <>Resend Mail</>}
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default SignUp;
