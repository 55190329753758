import { Button } from '@libs/atoms/Button';
import { InfoRounded, Visibility, VisibilityOff } from '@mui/icons-material';
import {
  CircularProgress,
  IconButton,
  InputAdornment,
  OutlinedInput,
  TextField,
  Tooltip
} from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import useAuth from 'hooks/useAuth';
import { login_strings as strings } from 'i18n/';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { PASSWORD_REGEX } from 'constants/validations';

import { passwordConditions } from '../utils';

import styles from './ResetPassword.module.scss';

enum InputType {
  TEXT = 'text',
  PASSWORD = 'password'
}
const animationProps = {
  initial: { y: -50, opacity: 0.2 },
  animate: { y: 0, opacity: 1 },
  exit: { y: -10, opacity: 0 }
};
enum PasswordType {
  PASSWORD = 'passwordType',
  CONFIRM_PASSWORD = 'confirmPasswordType'
}

interface IFormInput {
  email: string;
  password: string;
  confirmPassword: string;
  confirmPasswordType: InputType;
  passwordType: InputType;
  validPassword: boolean;
  isMatchingPassword: boolean;
}

const ResetPassword: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    getValues
  } = useForm<IFormInput>({
    defaultValues: {
      confirmPasswordType: InputType.PASSWORD,
      passwordType: InputType.PASSWORD,
      validPassword: true,
      isMatchingPassword: true
    }
  });

  const { isLoading, resetPassword, forgotPasswordError } = useAuth();
  const validPassword = watch('validPassword');
  const isMatchingPassword = watch('isMatchingPassword');

  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    const { password, email } = data;
    setValue('validPassword', PASSWORD_REGEX.test(password));
    setValue('isMatchingPassword', getValues('password') === getValues('confirmPassword'));
    if (PASSWORD_REGEX.test(password) && getValues('password') === getValues('confirmPassword')) {
      await resetPassword(password, email);
    }
  };

  const handleClickShowPassword = (type: 'confirmPasswordType' | 'passwordType') => {
    if (watch(type) === 'password') setValue(type, InputType.TEXT);
    else setValue(type, InputType.PASSWORD);
  };

  return (
    <div className="absolute top-[15%] flex h-full w-full justify-center">
      <div className={`flex w-[400px] flex-col p-5 ${styles.container}`}>
        <>
          <span className="mb-8 text-3xl font-semibold">{strings.password.forgotPassword}</span>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mt-2 flex h-[120px] flex-col">
              <span className="py-2 font-semibold">Email</span>
              <TextField
                className="w-[360px]"
                {...register('email', { required: true })}
                type="text"
                placeholder="Enter Your username"
                margin="normal"
                autoComplete="off"
              />
              <AnimatePresence>
                {errors.email && (
                  <motion.span {...animationProps} className="  py-1  text-sm text-red-600">
                    {strings.mail.enterEmail}
                  </motion.span>
                )}
              </AnimatePresence>
            </div>
            <div className="mb-5 mt-5 flex flex-col">
              <span className="py-2 font-semibold">{strings.password.password}</span>
              <OutlinedInput
                {...register('password', { required: true })}
                type={watch(PasswordType.PASSWORD)}
                placeholder="Password"
                autoComplete="off"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => handleClickShowPassword(PasswordType.PASSWORD)}
                      edge="end"
                    >
                      {watch(PasswordType.PASSWORD) === InputType.TEXT ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>

                    <Tooltip title={passwordConditions()}>
                      <IconButton aria-label="toggle password visibility" edge="end">
                        <InfoRounded />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                }
              />
              {errors.password && (
                <span className={`text-red-600 ${styles.errorMsg}`}>
                  {strings.password.enterPassword}
                </span>
              )}
              {!validPassword && (
                <span className={`text-red-600 ${styles.errorMsg}`}>
                  {strings.password.enterValidPassword}
                </span>
              )}
            </div>

            <div className="mb-5 mt-5 flex flex-col">
              <span className="py-2 font-semibold">{strings.password.confirmPassword}</span>
              <OutlinedInput
                {...register('confirmPassword', { required: true })}
                type={watch(PasswordType.CONFIRM_PASSWORD)}
                placeholder="Confirm Password"
                autoComplete="off"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => handleClickShowPassword(PasswordType.CONFIRM_PASSWORD)}
                      edge="end"
                    >
                      {watch(PasswordType.CONFIRM_PASSWORD) === InputType.TEXT ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {errors.confirmPassword && (
                <span className={`text-red-600 ${styles.errorMsg}`}>
                  {strings.password.enterPassword}
                </span>
              )}
              {!isMatchingPassword && (
                <span className={`text-red-600 ${styles.errorMsg}`}>
                  {strings.password.matchPassword}
                </span>
              )}
            </div>

            {forgotPasswordError && (
              <div className={`justify-end text-red-600 ${styles.errorMsg}`}>
                {forgotPasswordError}
              </div>
            )}
            <Button
              onClick={handleSubmit(onSubmit)}
              className={`${styles.button} mt-8`}
              variant="primary"
            >
              {isLoading ? (
                <CircularProgress color="secondary" size={25} />
              ) : (
                <>{strings.password.changePassword}</>
              )}
            </Button>
          </form>
        </>
      </div>
    </div>
  );
};

export default ResetPassword;
