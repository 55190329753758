import { Button } from '@libs/atoms/Button';
import { CircularProgress, TextField } from '@mui/material';
import useAuth from 'hooks/useAuth';
import { login_strings as strings } from 'i18n';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { EMAIL_REGEX } from 'constants/validations';

import styles from './SendEmail.module.scss';

interface IFormInput {
  email: string;
  validEmail: boolean;
}

const SendEmail: React.FC<{
  setForgotPass: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ setForgotPass }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue
  } = useForm<IFormInput>({
    defaultValues: {
      validEmail: true
    }
  });

  const { isLoading, forgotPassword, signUpError, forgotPasswordError } = useAuth();
  const validEmail = watch('validEmail');

  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    const { email } = data;
    setValue('validEmail', EMAIL_REGEX.test(email));
    if (EMAIL_REGEX.test(email)) {
      await forgotPassword(email);
      if (!forgotPasswordError) {
        setForgotPass((prev) => !prev);
      }
    }
  };

  return (
    <>
      <div className={`flex flex-col p-20 ${styles.container}`}>
        <span className="flex justify-around text-xl font-bold">
          {strings.signUp.forgotPasswordQ}
        </span>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col ">
            <span className="mt-8 text-lg font-semibold">{strings.mail.email}</span>
            <TextField
              {...register('email', { required: true })}
              type="text"
              placeholder="Enter Your email"
              margin="normal"
              name="email"
              autoComplete="off"
              InputProps={{
                classes: {
                  root: `${styles.textRoot}`,
                  input: `${styles.textInput}`
                },
                disableUnderline: true
              }}
              FormHelperTextProps={{
                className: styles.helperText
              }}
            />
            {errors.email && (
              <span className={`text-sm text-red-600 ${styles.errorMsg}  `}>
                {strings.signUp.enterEmail}
              </span>
            )}
            {!validEmail && (
              <span className={`text-sm text-red-600  ${styles.errorMsg}`}>
                {strings.signUp.validEmail}
              </span>
            )}
          </div>
          {signUpError && (
            <div className="flex justify-end text-sm text-red-600">{signUpError?.message}</div>
          )}
          <Button onClick={handleSubmit(onSubmit)} className={styles.button} variant="primary">
            {isLoading ? (
              <CircularProgress color="secondary" size={25} />
            ) : (
              <>{strings.signUp.sendMail}</>
            )}
          </Button>
          <div className="flex justify-center">
            <Button
              className="mt-4 !rounded-md"
              variant="generic"
              onClick={() => setForgotPass((type) => !type)}
            >
              <span className="MuiButton-label">{strings.signUp.goBack}</span>
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};

export default SendEmail;
