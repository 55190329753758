import { AxiosResponse } from 'axios';
import { AuthTokens, SignupPayload } from 'types/AuthTokens';
import { CreateUserResponseType, User } from 'types/User';

import {
  CHANGE_PASSWORD,
  DELELTE_USER_PATH,
  FORGOT_PASSWORD,
  LOGIN_PATH,
  REFRESH_TOKEN_PATH,
  SEND_VERIFY_EMAIL,
  SIGNUP_PATH,
  UPATE_USER_DETAILS,
  VERIFY_EMAIL
} from 'constants/endpoints';

import client from './apiClient';
import CustomerAuth from './auth';

const login = async (username: string, password: string): Promise<void> => {
  const useFormdata = new FormData();
  useFormdata.append('username', username);
  useFormdata.append('password', password);
  const response = await client.post(LOGIN_PATH, useFormdata);
  const { access_token, refresh_token } = response.data;
  CustomerAuth.setTokens({
    accessToken: access_token,
    refreshToken: refresh_token
  });
};

const createAccount = async (
  payload: SignupPayload
): Promise<AxiosResponse<CreateUserResponseType>> => {
  const { data } = await client.post(SIGNUP_PATH, {
    ...payload
  });
  const { access_token, refresh_token } = data;
  CustomerAuth.setTokens({
    accessToken: access_token,
    refreshToken: refresh_token
  });
  return data;
};

const logout = async (): Promise<void> => {
  CustomerAuth.clearTokens();
};

const forgotUserPassword = async (email: string): Promise<boolean> => {
  const response = await client.post(FORGOT_PASSWORD, {
    email
  });
  const { success } = response.data;
  return success;
};

const resetUserPassword = async (password: string, email: string): Promise<boolean> => {
  const params = new URLSearchParams(window.location.search);
  let access_token = '';
  if (params.has(AuthTokens.ACCESS_TOKEN)) {
    access_token = params.get(AuthTokens.ACCESS_TOKEN) || '';
  }
  CustomerAuth.setTokens({
    accessToken: access_token,
    refreshToken: ''
  });
  const response = await client.post(CHANGE_PASSWORD, {
    password,
    email
  });
  CustomerAuth.clearTokens();
  const { success } = response.data;
  return success;
};

const updateUserDetails = async (
  name: string,
  first_name: string,
  last_name: string,
  phone_number: string
): Promise<User> => {
  const { data } = await client.put(
    UPATE_USER_DETAILS,
    {
      name,
      first_name,
      last_name,
      phone_number
    },
    { headers: { Authorization: 'Bearer ' + CustomerAuth.accessToken } }
  );
  return data;
};

const sendVerificationMail = async (email: string): Promise<boolean> => {
  const response = await client.post(SEND_VERIFY_EMAIL, {
    email
  });
  const { success } = response.data;
  return success;
};
const getAccessToken = async () => {
  const response = await client.post(REFRESH_TOKEN_PATH, {
    headers: { Authorization: 'Bearer ' + CustomerAuth.refreshToken }
  });
  return response;
};
const deleteUser = async () => {
  const response = await client.delete(DELELTE_USER_PATH, {
    headers: { Authorization: 'Bearer ' + CustomerAuth.accessToken }
  });
  return response;
};
const verifyEmail = async (): Promise<boolean> => {
  const params = new URLSearchParams(window.location.search);
  let access_token = '';
  if (params.has(AuthTokens.ACCESS_TOKEN)) {
    access_token = params.get(AuthTokens.ACCESS_TOKEN) || '';
  }
  CustomerAuth.setTokens({
    accessToken: access_token,
    refreshToken: ''
  });
  const response = await client.post(VERIFY_EMAIL, {});
  CustomerAuth.clearTokens();
  const { status } = response;
  return status === 200;
};

export {
  createAccount,
  deleteUser,
  forgotUserPassword,
  getAccessToken,
  login,
  logout,
  resetUserPassword,
  sendVerificationMail,
  updateUserDetails,
  verifyEmail
};
