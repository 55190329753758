import { Button } from '@libs/atoms/Button';
import { Email } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import useAuth from 'hooks/useAuth';
import React from 'react';

import styles from './VerifyEmail.module.scss';

const VerifyEmail: React.FC = () => {
  const { loginError, isLoading, verifyUserMail } = useAuth();
  const handleSubmit = () => verifyUserMail();
  return (
    <div className={`flex flex-col items-center justify-center ${styles.container}`}>
      <>
        <div className="flex flex-row items-center justify-center">
          <Email className={styles.emailIcon} />
          <span className={styles.heading}>
            Thanks for signing up. Please Verify your email to continue.
          </span>

          {loginError && (
            <div className="flex text-sm text-red-500" style={{ justifyContent: 'flex-end' }}>
              {loginError}
            </div>
          )}
        </div>
        <Button
          onClick={handleSubmit}
          className="rounder-lg mt-12 w-[300px]"
          color="primary"
          variant="secondary"
        >
          {isLoading ? <CircularProgress color="secondary" size={25} /> : <>Verify</>}
        </Button>
      </>
    </div>
  );
};

export default VerifyEmail;
