import { Button } from '@libs/atoms/Button';
import { CircularProgress, TextField } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import useAuth from 'hooks/useAuth';
import { login_strings as strings } from 'i18n';
import React, { useCallback, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { EMAIL_NOT_VERIFIED } from 'types/Error';

import styles from './Login.module.scss';

const animationProps = {
  initial: { y: -50, opacity: 0.2 },
  animate: { y: 0, opacity: 1 },
  exit: { y: -10, opacity: 0 }
};

interface IFormInput {
  username: string;
  password: string;
}

const Login: React.FC<{
  setIsNewUser: React.Dispatch<React.SetStateAction<boolean>>;
  setForgotPass: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ setForgotPass, setIsNewUser }) => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<IFormInput>();
  const { loginError, isLoading, loginUser } = useAuth();
  const onSubmit: SubmitHandler<IFormInput> = useCallback(
    async (data) => {
      const { username, password } = data;
      if (!isLoading) loginUser(username, password);
    },
    [isLoading, loginUser]
  );

  useEffect(() => {
    const listener = (event) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        const submitHoc = handleSubmit(onSubmit);
        submitHoc();
        event.preventDefault();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [handleSubmit, onSubmit]);

  const onResendEmail: SubmitHandler<IFormInput> = async ({ username }) => {
    //
  };

  return (
    <div className={`p-2 ${styles.container}`}>
      <div className="mb-16 flex w-full flex-col items-center">
        <span className="text-3xl font-bold ">{strings.login.loginAccount}</span>
      </div>
      <form id="loginForm" onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-2 flex h-[120px] flex-col">
          <span className="py-2 font-semibold">Username</span>
          <TextField
            className="w-[400px]"
            {...register('username', { required: true })}
            type="text"
            placeholder="Enter Your username"
            margin="normal"
            autoComplete="off"
          />
          <AnimatePresence>
            {errors.username && (
              <motion.span {...animationProps} className="  py-1  text-sm text-red-600">
                {strings.mail.enterEmail}
              </motion.span>
            )}
          </AnimatePresence>
        </div>
        <div className="mt-8 flex h-[120px] flex-col">
          <span className="f-16 app-font p-gray py-1 font-semibold">
            {strings.password.password}
          </span>
          <TextField
            {...register('password', { required: true })}
            type="password"
            placeholder="Type your password"
            margin="normal"
            autoComplete="off"
          />
          <AnimatePresence>
            {errors.password && (
              <motion.span {...animationProps} className="py-1 text-sm text-red-600">
                {strings.password.enterYourPassword}
              </motion.span>
            )}
          </AnimatePresence>
        </div>
        <div className="min-h-[30px]">
          <AnimatePresence>
            {loginError && !errors.password && (
              <motion.div
                {...animationProps}
                className={`${styles.error} d-flex py-1  text-sm text-red-600`}
                style={{ justifyContent: 'flex-start' }}
              >
                {loginError}
              </motion.div>
            )}
          </AnimatePresence>
        </div>
        <AnimatePresence>
          {loginError !== EMAIL_NOT_VERIFIED && (
            <Button
              onClick={handleSubmit(onSubmit)}
              type="submit"
              form="loginForm"
              variant="primary"
              className="mt-1 !w-full !rounded-md"
            >
              {isLoading ? (
                <CircularProgress color="secondary" size={25} />
              ) : (
                <>{strings.login.loginNow}</>
              )}
            </Button>
          )}
        </AnimatePresence>

        <div className="mt-8 flex flex-col items-center justify-between sm:flex-row">
          {loginError !== EMAIL_NOT_VERIFIED ? (
            <Button
              className={styles.forgotButton}
              variant="generic"
              onClick={() => setForgotPass((type) => !type)}
            >
              <span className="MuiButton-label !normal-case">
                {strings.password.forgotPassword}
              </span>
            </Button>
          ) : (
            <Button
              variant="generic"
              className={styles.button}
              onClick={handleSubmit(onResendEmail)}
            >
              {isLoading ? (
                <CircularProgress color="secondary" size={25} />
              ) : (
                <>{strings.login.resendVerification}</>
              )}
            </Button>
          )}
          <div className="ml-4 mt-2 flex items-center justify-between">
            <motion.span whileHover={{ scale: 1.05 }} className="font-interV " />
            <Button
              className={` !rounded-md ${styles.createAccount}`}
              variant="generic"
              onClick={() => setIsNewUser((type) => !type)}
            >
              <span className="w-[250px]">{strings.login.createAccount}</span>
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Login;
